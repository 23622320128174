.nav-fixed{
  position:fixed;
  width:100%;
  right:0;
  top:0;
  min-height: unset;
  max-height: 64px;
  z-index:99;
}
header svg {
  margin-top: 3px;
}
aside.sidebar {
  position:fixed;
  right:0;
  top:64px;
  width:180px;
  height:calc(100vh - 64px);
  z-index: 99;
  transition: width .3s ease-in-out;
}

.sidebar-menu{
  margin-top:10px;
}
.sidebar-menu-item a {
  display:flex; 
  align-items:center;
  padding:15px;
}
.sidebar-menu-item a.active {
  background: #dfdede;
}
.sidebar-menu-item span{
  margin-right:7px;

}
.sidebar.collapse {
  width:60px
}
.sidebar.collapse .sidebar-menu li span {
  display:none;
}

.content {
  width: calc(100% - 60px);
  margin-right: 60px;
  padding-right:10px;
  padding-left:20px;
}
.full-content{
  width: calc(100% - 180px);
  margin-right: 180px;
}
