/* profile page */
.avatar {
  background: #1e1e2230;
margin-bottom: 60px;
padding: 20px;
height: 100px;
}

.avatar-container {
  width: 150px;
  height: 150px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  border: 2px solid #ccc;
  background: #e5e5e5;
}
.avatar-container:before{
  content:'';
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background:#0005;
  z-index:1;
  opacity:0;
  transition: opacity .2s ease-in-out
}
.avatar-container:hover:before{
  opacity:1
}
.avatar-container img{
  width: 100%;
  height: 100%;
  object-fit: cover;

}
.avatar-container span{
  position: absolute;
  left: 0;
  top:0;
  width: 100%;
  height: 100%;
  background: #00000042;
  border-radius: 50%;
  display: flex;
  justify-content:center;
  align-items: center;
  z-index:2;
  opacity:0;
  transition: opacity .2s ease-in-out
}
.avatar-container:hover span{
  opacity: 1;
}
.avatar-container input{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  opacity:0;
}
.user-profile-info li {
  display: flex;
margin: 10px 0;
border-bottom: 1px solid #ccc6;
width: 350px;
padding: 5px;
}

.user-profile-info li svg {
  width: 27px;
  height: 27px;
  margin-left: 5px;
  border: 1px solid #ccc8;
  padding: 3px;
  border-radius: 4px;
  background: #eae9e9;
  color: #555;
}



.user-permissions-container {
  
}
.user-permissions-container h3 {
  font-size: 1.5rem;
}
.user-permissions{
  position:relative;
  margin-top: 15px;
  background: #eae9e9;
  border: 1px solid #ccc;
  padding-right: 10px;
  min-width:160px;
  width: fit-content;
  border-right: 2px solid #ccc;
}
.user-permissions h4{
  position:relative;
  color: #333;
  padding-right:4px
}
.user-permissions h4:before {
  content:'';
  position:absolute;
  top: 5px;
  right: -20px;
  height: 20px;
  width: 20px;
  border-radius:50%;
  background:#ccc;
}
.user-permissions .flex > span {
  display: flex;
  margin-left: 10px;
  align-items: center;
  min-width: 60px;
  color: #555;
  margin-top: 15px;
  font-size: 14px;
}
.user-permissions .flex > span span{
  order:2
}
