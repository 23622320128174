.dashboard {}
.results {
  display: flex;
  flex-wrap: wrap;  
}
.results .result-box  {
  background: #ccc;
padding: 5px;
border: 1px solid aliceblue;
margin: 5px;
width: calc(50% - 10px);
display: flex;
justify-content: space-between;
min-width:200px;
flex:1;
}
.results .result-box:first-of-type{
  background-image: radial-gradient(#ec1818eb, #ff4545);
  color:#700;
}
.results .result-box:nth-of-type(2){
  background-image: radial-gradient(#18ec57eb, #45ff96);
  color: #0c3500;
}
.results .result-box:nth-of-type(3){
  background-image: radial-gradient(#18a6eceb, #45b9ff);
  color: #02485e;
}
.results .result-box:nth-of-type(4){
  background-image: radial-gradient(#eca318eb, #ff8b45);
  color: #7d3b00;
}
.results .result-box .result-box-content h4 {
  font-size: 2rem;
}
.results .result-box .result-box-content a {
  font-size: 1.2rem;
}
.results .result-box span{
  order: 2
}
.results .result-box svg  {
  width: 45px;
  height: 45px;
  color :#fff;
}
.dashboard-sidebar-box{
  background: #000;
  border-radius: 20px;
}
.dashboard-sidebar-box figure{
  background: #222;
  border-radius: 20px;
  padding: 20px;
}
.dashboard-sidebar-box figure img {
  max-width: 120px;
  border-radius: 50%;
}
h3 {
  color: #fff;
  font-size: 26px;
  text-transform: capitalize;
}