@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@100;200;300;400;500;600;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  padding-top:65px;
  overflow-x: hidden;
  font-family: 'IBM Plex Sans Arabic', sans-serif;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.overflow-x-auto::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
.overflow-x-auto::-webkit-scrollbar-thumb ,
.overflow-x-auto::-webkit-scrollbar-track {
  background: transparent
}





/* Login page */
.login-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  z-index: 9999;
background: #fff;
}
.login-container form {
  margin: auto;
  min-width: 300px;
}

form .MuiTypography-root  {
  text-align: center;
  margin-top:20px;

}
form .MuiTypography-root a {
  color: #5664d2;
}


.MuiAlert-icon ,
.css-1ytlwq5-MuiAlert-icon{
  margin-right:unset;
  margin-left:12px;
}
.MuiAlert-standard {
  width: fit-content;
}

.MuiBox-root.css-yibgmg{
  background: #fff;
width: 100%;
position: fixed;
top: 0;
left: 0;
z-index: 9999;
}
.not-found-redirect {
  border-radius: 1px;
  color: #fff;
  width: 100px;
  margin: 0 auto;
  margin-top: 0px;
  display: block;
  text-align: center;
  margin-top: 20px;
}




/* Update popup */

.form-update-permission{
  padding: 10px ;
  margin-bottom: 15px;
  background: #dfdcdc;
  margin: 5px 2px ;
  border: 1px solid #ccc8;
  border-radius: 5px;

}

form .MuiTypography-root{
  margin-top:0;
}


.form-update-permission h4 {
  white-space:pre;
}
.form-update-permission .fields{
  margin-top: 5px;
  display: flex;
  flex-direction: column;
}
.MuiFormControlLabel-root
.form-update-permission label {
  margin:0 !important; 
}



.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon{
  right:unset !important; 
  left:7px !important
}
.label-right {
  transform: translate(-7px, 12px);
  background: #fff;
  font-size: 14px;
  padding: 1px 3px;
}
.flex.flex-column {
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
}

.dashboard-head,
.dashboard-body
.dashboard-footer{
  margin-top: 40px 0;
}
.dashboard-line{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ccc;
  padding: 10px 0;
  border-left: none;
  border-right: none;
}
.members {
  display:flex;
}
.members span {
  margin-left: -20px;
}
.members img {
  max-width: 40px;
  border-radius: 50px;
  margin-left: 10px;
}
.quick-add a {
  background: #2cacee;
  padding: 5px;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  margin-left: 5px;
}
.quick-add a:nth-of-type(2){
  background:#fd5847
}
.quick-add a:nth-of-type(3){
  background: #2ebd06;
}