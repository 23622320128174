.steps-dot {
  justify-content: space-around;
padding: 20px 0;
align-items: center;
}
.steps-dot .dot {
  background: #dfdcdc;
  border-radius: 50%;
  width: 35px;
height: 35px;
text-align: center;
padding: 5px;
color: #888;
}
.steps-dot .dot.active {
  background: #5664d2;
  color: #fff;
}
.steps-dot .line {
  height: 3px;
background: #dfdcdc;
display: block;
flex: 1;
}
.steps-dot .line.active {
  background: #5664d2;
}


.MuiSelect-select {
  display:flex;
}
.MuiSelect-select img {
  width: 25px;
  height:25px;
  object-fit:cover;
  border-radius: 50%;
  margin-left: 6px;
}
.MuiSelect-select input{
  position:absolute;
  opacity:0;
  pointer-events: none;
}

.MuiMenuItem-root img {
  width: 30px;
  height: 30px;
  object-fit:cover;
  border-radius: 50%;
  margin-left: 10px;
}
.MuiMenuItem-root input{
  margin-left:5px;
}
span.overlay-content{
  right: 25px;
  position: absolute;
  top: 2px;
  left: 2px;
  background: #fff;
  z-index: 2;
  bottom: 2px;
  pointer-events: none;
}


.user-selected {}
.user-selected .user-selected-list{
  display: flex;
  flex-wrap: wrap;
  padding: 20px 5px;
}
.user-selected .user-selected-list .user-selected-list-item {
  display: flex;
background: #e1dede;
border-radius: 20px;
padding: 2px 1px 2px 5px;
border: 1px solid #ccc;
}
.user-selected .user-selected-list .user-selected-list-item img {
  max-width: 20px;
border-radius: 50%;
}

.user-selected .user-selected-list .user-selected-list-item span {
  margin-right: 3px;
  font-size: 14px;
}




.task-single {}
.task-single h3 {
  font-size: 1.3rem;
  margin-bottom: 10px;
}
.task-single .task-info {
  margin: 20px 0;
  display:flex;
  flex-wrap:wrap;
}
.task-single .task-info li {
  width:calc(50% - 8px);
  display: flex;
  align-items: center;
  background: #ececec;
  border: 1px solid #ccc8;
  border-radius: 3px;
  margin:4px;
}
.task-single .task-info li .icon { 
  padding: 6px;
  background: #dbdbdb;
  color: #777;
  margin: 3px 3px 3px 8px;
}
.task-single .task-info li strong {
  color: #444;
  margin-left: 6px;
  font-size: 1.1rem;
 }
.task-single .task-info li span:last-of-type { 
  font-size: 1.1rem;
}
.task-users{
  background: #ececec;
  border: 1px solid #ccc8;
  margin-top: 30px;
  padding: 10px;
}

.task-users .task-user-card {
  background: #dbdbdb;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  border: 2px solid #ccc7;
  position: relative;
padding-bottom: 30px;
}
.task-users .task-user-card img {
  border-radius: 50%;
  margin-bottom: 10px;
  width:100%;
  height:100%;
  object-fit:cover;
}
.task-users .task-user-card p,
.task-users .task-user-card h4{
  text-transform:capitalize
}
.text-user-status {
  position: absolute;
  bottom: 3px;
  right: 10px;
  font-size: 12px;
  padding: 3px 8px;
  border-radius: 10px;
}
.text-user-status.pending {
  background: #f9e390;
  color: #8c8c03;
}
.text-user-status.onwork {
  background: #90e0f9;
  color: #035f8c;  
}
.text-user-status.done {
  background: #93f990;
  color: #078c03;
}



/* Add comments */
.add-comment{
  padding: 20px 0;
}

.task-comment-box img,
.add-comment img{
  width: 70px;
  height: 70px;
  object-fit:cover;
  border-radius: 50%;
  margin-left: 15px;
}
.add-comment textarea{
  resize:none;
  min-height:100px;
}
.add-comment button {
  display:block;
  max-width: fit-content;
  margin-right: auto;
}

.task-comments,
.task-add-comment {
  max-width:600px;
}
.task-comment-box {
  align-items:flex-start;
  margin-bottom:20px;
  position:relative;
}
.task-comment-box-content  {
  position:relative;
  background: #ececec;
  padding: 10px;
  border-radius: 14px;
  border: 1px solid #ccc;
  flex:1;
}
.task-comment-box-content h4{
  font-size: 1.1rem;
  text-transform: capitalize;
  font-weight: bold;
  color: #333;
}
.task-comment-box-content p{
  font-size: .9rem;
  font-weight: 300;
  margin-top: 10px;
  margin-bottom: 10px;
}
.comment-control{
  position: absolute;
  left: 10px;
  font-size: 11px ;
  opacity:0;
  transition: opacity .2s ease-in-out
}
.comment-control button{
  margin-right:3px;
  padding: 1px 3px;
}
.task-comment-box-content .comment-control{
  opacity:1;
}


.task-comment-box .add-comment{
  display:none;
}
.task-comment-box.editing .add-comment{
  display:block !important;
}
.task-comment-box.editing > .flex{
  display: none !important;
}
.task-comment-box > .flex{
  display: flex;
}

.task-files-list{
  background: #ececec;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #ccc5;
}
.task-users h3,
.task-files-list h3{
  color: #444;
background: #fff;
width: fit-content;
padding: 2px 10px;
margin-top: -26px;
border-radius: 10px;
border: inherit;
}

.user-task-number{
  position: absolute;
bottom: -4px;
font-size: 11px;
background: #42bde3;
color: #fff;
padding: 1px 3px;
border-radius: 10px;
right: 0;
z-index: 2;
}